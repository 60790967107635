import { Injectable, OnInit, OnDestroy } from '@angular/core';
import { AppConstants } from '../../../config/index.constants';
import { ITableItemDataSource } from '../types/interfaces';
import { BehaviorSubject, Observable, Subscription, of, tap, finalize } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { IDrillDownParams, IFilterParams, IShowingStatus } from './snapshot';
import { COLUMN_NUMBER } from '../types/enums';
import { IPerformancePeriod } from '../types/interfaces';

const API_URL = AppConstants.api;

@Injectable({ providedIn: 'root' })


export class SnapshotService implements OnInit, OnDestroy {
	subscription = new Subscription();

	mainTableFilters: IFilterParams;
	performancePeriods: any[] = [];
	snapshotLogic = "";

	/** main table observable */
	snapshotData$: Observable<ITableItemDataSource>;
	snapshotDataSource = new BehaviorSubject<ITableItemDataSource>({} as unknown as ITableItemDataSource);

	/** drill down table observable */
	snapshotDrillDownData$: Observable<ITableItemDataSource>;
	snapshotDrillDownDataSource = new BehaviorSubject<ITableItemDataSource>({} as unknown as ITableItemDataSource);

	//** drill down params used for drill down export and pagination */
	drillDownParams: IDrillDownParams;
	totalAvailableRows: any;
	public response: any = {};
	sortColumn: any = COLUMN_NUMBER.COLUMN_ONE;
	sortAscending: boolean = true;

	constructor(private http: HttpClient) {
		this.snapshotData$ = this.snapshotDataSource.asObservable();
		this.snapshotDrillDownData$ = this.snapshotDrillDownDataSource.asObservable();
	}

	ngOnInit(): void {

	}

	//get list of performance periods for the filter
	async getPerformancePeriods(): Promise<void> {
		this.subscription.add(
			this.http.get<ArrayBuffer[]>(API_URL + 'snapshot/listOfPerformancePeriods')
				.subscribe(data => this.performancePeriods = data));
	}

  //get the Showing and Status info for display
	getSS(performancePeriod): Observable<IShowingStatus> {
		return this.http.get<IShowingStatus>(API_URL + 'snapshot/status/' + performancePeriod);
	}

	// Get the snapshot logic html for display
	getSnapshotLogic(): Observable<any> {
		return this.http.get(API_URL + 'snapshot/logic', { responseType: 'text' });
	}

	//get main table data
	snapshotView(params: IFilterParams): void {
		this.mainTableFilters = params;
		this.subscription.add(
			this.http.post<ArrayBuffer[]>(API_URL + 'snapshot/counts', params)
				.pipe(
					tap(data => {
						this.snapshotDataSource.next(data as unknown as ITableItemDataSource)
					})).subscribe()
		);
	}


	// export report from main table
	snapshotViewExport(): Observable<any> {
		// no drill down data - not able to confirm the route
		return this.http.post(API_URL + 'snapshot/countsExport', this.mainTableFilters, { observe: 'response', responseType: 'blob' as 'text' });
	}

	// get drill down table data
	snapshotDrilldown(params: IDrillDownParams): Observable<any> {
		this.drillDownParams = params;
		return this.http.post(API_URL + 'snapshot/drillDownInfo', params)
			.pipe(
				tap(data => {
					this.snapshotDrillDownDataSource.next(data as unknown as ITableItemDataSource);
				}
				),
			);
	}

	// export report from drill down table
	snapshotDrilldownExport(): Observable<any> {
		return this.http.post(API_URL + 'snapshot/drillDownInfoExport', this.drillDownParams, { observe: 'response', responseType: 'blob' as 'text' });
	}

	setResponse(data) {
		this.response = data;
	}

	setSortColumn(column) {
		this.sortColumn = column;
	}

	setSortAscending(sortAscending) {
		this.sortAscending = sortAscending;
	}

	getSortColumn() {
		return this.sortColumn;
	}

	getSortAscending() {
		return this.sortAscending;
	}

	ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}
}