<div class="reports-row" flex>
  <div [class.outline]="rowdata.expanded === true && rowdata.depth===1">
    <div flex class="table-row" [ngClass]="{outlineb: rowdata.expanded === true && rowdata.depth===1}">
      <div layout="row">
        <span *ngIf="showcheckbox" style="padding: 10px 0px 10px 10px">
          <mat-checkbox class="md-checkbox" [checked]="isChecked" [disabled]="isAlreadyChecked"
            (click)="checkBoxClicked(rowdata)" [ngClass]="{'custom-input-disabled': isAlreadyChecked }"></mat-checkbox>
        </span>
      </div>
      <div class="reportsRowLayoutRow">
        <span *ngIf="columnCount>0" [ngClass]="getColumnClasses('column1', 'header1')">
          <!-- Tooltip Column -->
          <p *ngIf="activateTooltip" custom-tooltip [tooltipTemplate]="tooltip" [ngClass]="{msixHighlight: (rowdata.column1.clickable === true && headerinfo.header1.value === 'MSIX ID'), 
               tooltipUnderline, clickable: rowdata.column1.clickable === true, worklistHighlight: (headerinfo.header1.value === 'Worklist ID' && 
               rowdata.column1.clickable === true)}" (click)="clickHandle(1)" (keypress)="clickHandle(1)"
            class="left-table-padding" data-id="{{ columnValue(1) }}">
            {{ columnValue(1) }}
          </p>
          <ng-template #tooltip >
            <div class="custom-tooltip" [innerHTML]="setIssueTooltip(rowdata.originalResult.logicRuleKey, 'key', 'text')"></div>
          </ng-template>
          <!-- Non Tooltip Column -->
          <div *ngIf="headerinfo.header1.value !== 'MSIX ID' && headerinfo.header1.value !== 'Worklist ID'">
            <p *ngIf="!activateTooltip" [ngClass]="{msixHighlight: (rowdata.column1.clickable === true && headerinfo.header1.value === 'MSIX ID'), 
              tooltipUnderline, clickable: rowdata.column1.clickable === true, worklistHighlight: (headerinfo.header1.value === 'Worklist ID' 
              && rowdata.column1.clickable === true)}" class="left-table-padding" data-id="{{ columnValue(1) }}"
              (click)="clickHandle(1)" (keypress)="clickHandle(1)">
              {{ columnValue(1) }}
            </p>
          </div>
          <a  *ngIf="msixIdShouldNavigate && headerinfo.header1.value === 'Worklist ID' && rowdata.column1.clickable === true"
            [routerLink]="['/worklistHistoryRecord']" target="_blank" [queryParams]="{ worklistId: rowdata?.originalResult.worklistKey ? rowdata?.originalResult.worklistKey : rowdata?.originalResult.worklistID, 
              worklistType: rowdata?.originalResult.type == 'From' ? 'LeavingOurArea' : 'MovedToOurArea', worklistStatus: ''}">
            <p *ngIf="!activateTooltip" [ngClass]="{msixHighlight: (rowdata.column1.clickable === true && 
              headerinfo.header1.value === 'MSIX ID'), tooltipUnderline, clickable: rowdata.column1.clickable === true, 
              worklistHighlight: (headerinfo.header1.value === 'Worklist ID' 
              && rowdata.column1.clickable === true)}" class="left-table-padding" data-id="{{ columnValue(1) }}"
              (click)="clickHandle(1)" (keypress)="clickHandle(1)">
              {{ columnValue(1) }}
            </p>
          </a>
          <span
            *ngIf="headerinfo.header1.value === 'Worklist ID' && (!msixIdShouldNavigate || rowdata.column1.clickable === false)">
            <p *ngIf="!activateTooltip" [ngClass]="{msixHighlight: (rowdata.column1.clickable === true && 
              headerinfo.header1.value === 'MSIX ID'), tooltipUnderline, clickable: rowdata.column1.clickable === true, 
              worklistHighlight: (headerinfo.header1.value === 'Worklist ID' 
              && rowdata.column1.clickable === true)}" class="left-table-padding" data-id="{{ columnValue(1) }}"
              (click)="clickHandle(1)" (keypress)="clickHandle(1)">
              {{ columnValue(1) }}
            </p>
          </span>
          <span tabindex="0" *ngIf="headerinfo.header1.value === 'MSIX ID' && !pfsReport" (click)="onMSIXIDClick(columnValue(1), 1)"
            (keypress)="onMSIXIDClick(columnValue(1), 1)">
            <p *ngIf="!activateTooltip" [ngClass]="{msixHighlight: (rowdata.column1.clickable === true && 
              headerinfo.header1.value === 'MSIX ID'), tooltipUnderline, clickable: rowdata.column1.clickable === true, 
              worklistHighlight: (headerinfo.header1.value === 'Worklist ID' && rowdata.column1.clickable === true)}"
              class="left-table-padding" data-id="{{ columnValue(1) }}">
              {{ columnValue(1) }}
            </p>
          </span>
          <span  *ngIf="headerinfo.header1.value === 'MSIX ID' && pfsReport" (click)="clickHandle(1)">
            <p *ngIf="!activateTooltip" [ngClass]="{msixHighlight: (rowdata.column1.clickable === true && 
              headerinfo.header1.value === 'MSIX ID'), tooltipUnderline, clickable: rowdata.column1.clickable === true, 
              worklistHighlight: (headerinfo.header1.value === 'Worklist ID' && rowdata.column1.clickable === true)}"
              class="left-table-padding msixId-underline" data-id="{{ columnValue(1) }}">
              {{ columnValue(1) }}
            </p>
          </span>
        </span>
        <span *ngIf="columnCount>1" [ngClass]="getColumnClasses('column2', 'header2')">
          <span *ngIf="headerinfo.header2.value === 'MSIX ID'" (click)="onMSIXIDClick(columnValue(2), 2)">
            <p tabindex="-1" (click)="clickHandle(2)"
              [ngClass]="{clickable: rowdata.column2.clickable === true, worklistHighlight: (headerinfo.header2.value === 'Worklist ID' && rowdata.column2.value &&  rowdata.column2.clickable === true), msixHighlight: (headerinfo.header2.value === 'MSIX ID' && rowdata.column2.value && rowdata.column2.clickable === true)}"
              class="left-table-padding" data-id="{{ columnValue(2) }}">
              {{ columnValue(2) }}
            </p>
          </span>
          <p tabindex="-1" *ngIf="headerinfo.header2.value !== 'MSIX ID'" (click)="clickHandle(2)"
            [ngClass]="{clickable: rowdata.column2.clickable === true, worklistHighlight: (headerinfo.header2.value === 'Worklist ID' && rowdata.column2.value &&  rowdata.column2.clickable === true), msixHighlight: (headerinfo.header2.value === 'MSIX ID' && rowdata.column2.value && rowdata.column2.clickable === true)}"
            class="left-table-padding" data-id="{{ columnValue(2) }}">
            {{ columnValue(2) }}
          </p>
        </span>
        <span *ngIf="columnCount>2" [ngClass]="getColumnClasses('column3', 'header3')">
          <a *ngIf="headerinfo.header3.value === 'MSIX ID' && rowdata.column3.clickable === true">
            <p tabindex="-1" (click)="clickHandle(3)"
              [ngClass]="{msixHighlight: (headerinfo.header3.value === 'MSIX ID' && rowdata.column3.clickable === true), clickable: rowdata.column3.clickable === true}"
              class="left-table-padding" data-id="{{ columnValue(3) }}">
              {{ columnValue(3) }}
            </p>
          </a>
          <div *ngIf="headerinfo.header3.value !== 'MSIX ID' || rowdata.column3.clickable === false">
            <p tabindex="-1" (click)="clickHandle(3)"
              [ngClass]="{msixHighlight: (headerinfo.header3.value === 'MSIX ID' && rowdata.column3.clickable === true), clickable: rowdata.column3.clickable === true}"
              class="left-table-padding" data-id="{{ columnValue(3) }}">
              {{ columnValue(3) }}
            </p>
          </div>
        </span>
        <span *ngIf="columnCount>3" [ngClass]="getColumnClasses('column4', 'header4')">
          <a *ngIf="headerinfo.header4.value === 'MSIX ID' && rowdata.column4.clickable === true">
            <p tabindex="-1" (click)="onMSIXIDClick(columnValue(4), 4)"
              [ngClass]="{msixHighlight: (headerinfo.header4.value === 'MSIX ID' && rowdata.column4.clickable === true), clickable: rowdata.column4.clickable === true}"
              class="left-table-padding" data-id="{{ columnValue(4) }}">
              {{ columnValue(4) }}
            </p>
          </a>
          <div *ngIf="headerinfo.header4.value !== 'MSIX ID' || rowdata.column4.clickable === false">
            <p tabindex="-1" (click)="clickHandle(4)"
            [ngClass]="{clickable: rowdata.column4.clickable === true, msixHighlight: (headerinfo.header4.value === 'MSIX ID' && rowdata.column4.clickable === true)}"
            class="left-table-padding" data-id="{{ columnValue(4) }}">
            {{ columnValue(4) }}
          </p>
          </div>
        </span>
        <span *ngIf="columnCount>4" [ngClass]="getColumnClasses('column5', 'header5')">
          <p tabindex="-1" (click)="clickHandle(5)" [ngClass]="{clickable: rowdata.column5.clickable === true}"
            class="left-table-padding">
            {{ columnValue(5) }}
          </p>
        </span>
        <span *ngIf="columnCount>5" [ngClass]="getColumnClasses('column6', 'header6')">
          <p tabindex="-1" (click)="clickHandle(6)" [ngClass]="{clickable: rowdata.column6.clickable === true}"
            class="left-table-padding">
            {{ columnValue(6) }}
          </p>
        </span>
        <span *ngIf="columnCount>6" [ngClass]="getColumnClasses('column7', 'header7')">
          <p tabindex="-1" (click)="clickHandle(7)"
            [ngClass]="{worklistHighlight:  (headerinfo.header7.value === 'Worklist ID' && rowdata.column7.clickable === true), clickable: rowdata.column7.clickable === true}"
            class="left-table-padding" data-id="{{ columnValue(7) }}">
            {{ columnValue(7) }}
          </p>
        </span>
        <span *ngIf="columnCount>7" [ngClass]="getColumnClasses('column8', 'header8')">
          <p tabindex="-1" (click)="clickHandle(8)" [ngClass]="{clickable: rowdata.column8.clickable === true}"
            class="left-table-padding">
            {{ columnValue(8) }} </p>
        </span>
        <span *ngIf="columnCount>8" [ngClass]="getColumnClasses('column9', 'header9')">
          <p tabindex="-1" (click)="clickHandle(9)" [ngClass]="{clickable: rowdata.column9.clickable === true}"
            class="left-table-padding">
            {{ columnValue(9) }} </p>
        </span>
        <span *ngIf="columnCount>9" [ngClass]="getColumnClasses('column10', 'header10')">
          <p tabindex="-1" (click)="clickHandle(10)" [ngClass]="{clickable: rowdata.column10.clickable === true}"
            class="left-table-padding">
            {{ columnValue(10) }} </p>
        </span>
        <span *ngIf="columnCount>10" [ngClass]="getColumnClasses('column11', 'header11')">
          <p tabindex="-1" (click)="clickHandle(11)" [ngClass]="{clickable: rowdata.column11.clickable === true}"
            class="left-table-padding">
            {{ columnValue(11) }} </p>
        </span>
        <span *ngIf="columnCount>11" [ngClass]="getColumnClasses('column12', 'header12')">
          <p tabindex="-1" (click)="clickHandle(12)" [ngClass]="{clickable: rowdata.column12.clickable === true}"
            class="left-table-padding">
            {{ columnValue(12) }} </p>
        </span>
        <span *ngIf="rowdata.groupingRow" class="column clickable chevron" (click)="rowItemClicked(rowdata, '')" tabindex="0" 
          (keypress)="rowItemClicked(rowdata, '')">
          <i *ngIf="rowdata.expanded && rowdata.groupingRow" class="fa fa-chevron-up accordion-icon" aria-hidden="true"
            alt="accordion collapsed"></i>
          <i *ngIf="!rowdata.expanded && rowdata.groupingRow" class="fa fa-chevron-down accordion-icon"
            aria-hidden="true" alt="accordion expanded"></i>
        </span>
        <span *ngIf="!rowdata.groupingRow && multiLeveled === true" class="chevyHolder">
        </span>
      </div>
    </div>
    <!--If a row can expand to show more rows of data, reportsRow is called again to show those expanded rows-->
    <div *ngIf="rowdata.expanded">
      <reports-row layout="row" flex *ngFor="let rowinfo of rowdata.rows" [headerinfo]="headerinfo"
        [multiLeveled]="multiLeveled" [rowdata]="rowinfo" [columnCount]="columnCount" [sortColumn]="sortColumn"
        (mycallback)="rowItemClicked($event)">
      </reports-row>
    </div>
  </div>

</div>